@import "../../../../public/styles/variables";

.needHelp {
  // width: 100%;
  &__btn {
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to right, #77530a, $first-color, #77530a, #77530a, $first-color, #77530a);
    background-size: 250%;
    background-position: left;
    // color: $first-color;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 1s;
    overflow: hidden;
    color: $first-color;
    text-transform: uppercase;
    z-index: 1;

    &::before {
      position: absolute;
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 97%;
      height: 90%;
      border-radius: 8px;
      transition-duration: 1s;
      background-color: rgba(0, 0, 0, 0.842);
      background-size: 200%;
      z-index: -1;
    }

    &:hover {
      background-position: right;
      transition-duration: 1s;
    }

    &:hover::before {
      background-position: right;
      transition-duration: 1s;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &__terms {
    margin: 20px 0;
    color: $white;
    font-size: 18px;
    font-family: $english-regular;
    font-weight: 500;
    cursor: pointer;
  }
}
